import { createApp } from "vue";
import router from "@/router";
import store from "@/store";
import App from "@/App";
import VueLazyLoad from "vue3-lazyload";

import axios from "@/libs/axios";
import { i18n } from "@/libs/i18n";

import "@/global-components";

const app = createApp(App);

app.provide("$axios", axios);

app.use(router);
app.use(store);
app.use(i18n);
app.use(VueLazyLoad);

app.mount("#app");
