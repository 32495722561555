<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  mounted() {
    this.setUpHeight();
    window.addEventListener("resize", this.setUpHeight);
  },

  unmounted() {
    window.removeEventListener("resize", this.setUpHeight);
  },

  methods: {
    setUpHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/main.scss"></style>
