import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:id",
    name: "home",
    component: () => import("@/views/Home"),
    props: true,
  },
  {
    path: "/menu/:alias",
    name: "menu",
    component: () => import("@/views/Menu"),
    props: true,
  },
  {
    path: "/menu/:alias/cart",
    name: "cart",
    component: () => import("@/views/Cart"),
    props: true,
  },
  {
    path: "/menu/:alias/order",
    name: "createOrder",
    component: () => import("@/views/CreateOrder"),
    props: true,
  },
  {
    path: "/menu/:alias/orders",
    name: "orders",
    component: () => import("@/views/Orders"),
    props: true,
  },
  {
    path: "/staff",
    name: "staff",
    component: () => import("@/views/Staff"),
  },
  {
    path: "/tips/:id",
    name: "tips",
    component: () => import("@/views/Person"),
    props: true,
  },
  {
    path: "/status",
    name: "status",
    component: () => import("@/views/Status"),
    props: true,
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "error-404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
