import { inject } from "vue";
import { createStore } from "vuex";

// =============================================================================

const getCartItemsFromLS = () => {
  const cart = localStorage.getItem("choyga_cart");

  if (cart) {
    const parsedCart = JSON.parse(cart);

    if (Array.isArray(parsedCart)) {
      return {};
    }

    return parsedCart;
  }

  return {};
};

const updateCartItemsInLS = (items) => {
  localStorage.setItem("choyga_cart", JSON.stringify(items));
};

// =============================================================================

const updateServicePercentageInLS = (value) => {
  localStorage.setItem("choyga_service_percentage", value);
};

const getServicePercentageInLS = () => {
  return localStorage.getItem("choyga_service_percentage");
};

// =============================================================================

const updateLastPersonNumberInLS = (value) => {
  localStorage.setItem("choyga_person_number", value);
};

const getLastPersonNumberInLS = () => {
  return localStorage.getItem("choyga_person_number");
};

// =============================================================================

const updateOrdersInLS = (menuId, orders) => {
  localStorage.setItem(`choyga_orders_${menuId}`, JSON.stringify(orders));
};

const getOrdersFromLS = (menuId) => {
  const orders = localStorage.getItem(`choyga_orders_${menuId}`);

  return orders ? JSON.parse(orders) : [];
};

// =============================================================================

const store = createStore({
  state() {
    return {
      menuData: {},
      categoryId: null,
      cartItems: getCartItemsFromLS(),

      feedbackSuggestions: {},
      colleagues: [],
      lastPersonNumber: getLastPersonNumberInLS(),
      isPaymentError: false,
      person: {
        id: null,
        description: "",
        name: "",
        number: "",
        photo: "",
        profession: "",
        tipsAvailable: false,
        anotherEmployeeAvailable: false,
      },
      reviewStep: 1,
      review: {
        rate: 0,
        feedback: "",
        customFeedback: "",
        tips: null,
      },

      orders: null,
    };
  },
  getters: {
    menuId(state) {
      return state.menuData?.id;
    },
    menuAlias(state) {
      return state.menuData?.alias ?? "";
    },

    servicePercentage(state) {
      return (
        state.menuData?.service_percentage || getServicePercentageInLS() || 0
      );
    },
    isOrderEnabled(state) {
      return state.menuData?.is_online_order ?? false;
    },

    categories(state) {
      return state.menuData?.categories ?? [];
    },
    currentCategory(state) {
      return state.menuData?.categories?.find(
        (category) => category.id === state.categoryId
      );
    },

    cartItems(state) {
      return state?.cartItems[state.menuData.alias] ?? [];
    },
    cartTotalAmount(state) {
      if (!state.cartItems[state.menuData.alias]) {
        return 0;
      }

      return state.cartItems[state.menuData.alias]
        .map((item) => item.amount)
        .reduce(
          (previousAmount, currentAmount) => previousAmount + currentAmount,
          0
        );
    },

    feedbackSuggestions(state) {
      return state.feedbackSuggestions[state.review.rate];
    },

    sortedOrders(state) {
      return state.orders?.sort((a, b) => b.id - a.id) ?? [];
    },
    hasOrders(state) {
      return state.orders.length > 0;
    },
  },
  mutations: {
    updateMenuData(state, data) {
      state.menuData = data;
    },

    changeCurrentCategory(state, id) {
      state.categoryId = id;
    },
    setCurrentCategory(state, id) {
      state.categoryId = id;
    },

    updateCartItems(state, items) {
      state.cartItems = items;
    },

    saveRating(state, rating) {
      state.review.rate = rating;
    },
    nextStep(state) {
      state.reviewStep = state.reviewStep + 1;
    },
    prevStep(state) {
      state.reviewStep = state.reviewStep - 1;
    },
    setPerson(state, person) {
      state.lastPersonNumber = person.number;
      state.person = person;
    },
    setColleagues(state, colleagues) {
      const elemList = [];

      colleagues.forEach((item) => {
        if (elemList.length === 0) {
          elemList.push({ profession: item.profession, personal: [item] });
        } else {
          const isset = elemList.find(
            (elem) => elem.profession === item.profession
          );

          if (isset) {
            elemList.forEach((elem) => {
              if (elem.profession === item.profession) {
                elem.personal.push(item);
              }
            });
          } else {
            elemList.push({ profession: item.profession, personal: [item] });
          }
        }
      });

      state.colleagues = elemList;
    },
    setSuggestion(state, feedbackSuggestions) {
      state.feedbackSuggestions = feedbackSuggestions;
    },
    selectSuggest(state, suggest) {
      state.review.customFeedback = suggest;
    },
    addComment(state, comment) {
      state.review.feedback = comment;
    },
    addTips(state, price) {
      state.review.tips = price;
    },
    resetReview(state) {
      state.reviewStep = 1;
      state.review = {
        rate: 0,
        feedback: "",
        customFeedback: "",
        tips: null,
      };
    },
    setPaymentError(state, isPaymentError) {
      state.isPaymentError = isPaymentError;
    },

    setOrders(state, orders) {
      state.orders = orders;
    },
  },
  actions: {
    async fetchMenuData({ commit }, alias) {
      const httpClient = inject("$axios");

      try {
        const response = await httpClient.get(`/menu/${alias}`);

        if (response?.data === undefined) {
          return;
        }

        const { data } = response;

        commit("updateMenuData", data);
        commit("setCurrentCategory", data?.categories?.[0]?.id);
        commit("setOrders", getOrdersFromLS(data?.id));

        updateServicePercentageInLS(data?.service_percentage);
      } catch (error) {
        commit("updateMenuData", {});
      }
    },

    changeCurrentCategory({ commit }, id) {
      commit("changeCurrentCategory", id);
    },

    addToCart({ commit, state }, item) {
      const cartItems = JSON.parse(JSON.stringify(state.cartItems));
      const aliasItems = cartItems[state.menuData.alias] || [];

      cartItems[state.menuData.alias] = [...aliasItems, ...[item]];

      updateCartItemsInLS(cartItems);

      commit("updateCartItems", cartItems);
    },
    removeFromCart({ commit, state }, item) {
      const cartItems = JSON.parse(JSON.stringify(state.cartItems));
      const aliasItems = cartItems[state.menuData.alias] || [];

      const aliasItemIndex = aliasItems.findIndex(
        (cartItem) => cartItem.id === item.id
      );
      const aliasFilteredItems = aliasItems.filter(
        (_, cartItemIndex) => cartItemIndex !== aliasItemIndex
      );

      cartItems[state.menuData.alias] = aliasFilteredItems;

      updateCartItemsInLS(cartItems);

      commit("updateCartItems", cartItems);
    },
    clearCart({ commit, state }) {
      const cartItems = JSON.parse(JSON.stringify(state.cartItems));

      cartItems[state.menuData.alias] = [];

      updateCartItemsInLS(cartItems);

      commit("updateCartItems", cartItems);
    },

    saveRating({ commit }, rating) {
      commit("saveRating", rating);
    },
    nextStep({ commit }) {
      commit("nextStep");
    },
    prevStep({ commit }) {
      commit("prevStep");
    },
    selectSuggest({ commit }, suggest) {
      commit("selectSuggest", suggest);
    },
    addComment({ commit }, comment) {
      commit("addComment", comment);
    },
    addTips({ commit }, price) {
      commit("addTips", price);
    },
    resetReview({ commit }) {
      commit("resetReview");
    },
    setPaymentError({ commit }, isPaymentError) {
      commit("setPaymentError", isPaymentError);
    },
    async fetchPersonalInfo({ commit }, personId) {
      updateLastPersonNumberInLS(personId);

      const httpClient = inject("$axios");

      try {
        const response = await httpClient.get(`/employee/${personId}`);

        if (response?.data === undefined) {
          return;
        }

        const { data } = response;
        const person = {
          id: data.id,
          description: data.description,
          name: data.name,
          number: data.number,
          photo: data.photo,
          profession: data.profession,
          tipsAvailable: data.tips_available,
          anotherEmployeeAvailable: data.another_employee_available,
        };

        commit("setPerson", person);
        commit("setColleagues", data.colleagues);
        commit("setSuggestion", data.feedback_suggestions);
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },

    saveOrder({ commit, state }, order) {
      const menuId = state.menuData?.id;
      const orders = state.orders;

      orders.push(order);

      updateOrdersInLS(menuId, orders);

      commit("setOrders", orders);
    },
  },
});

export default store;
