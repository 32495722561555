import { createI18n } from "vue-i18n";
import ru from "@/locales/ru.json";
import en from "@/locales/en.json";
import uz from "@/locales/uz.json";
import tr from "@/locales/tr.json";
import fr from "@/locales/fr.json";
import de from "@/locales/de.json";
import zh from "@/locales/zh.json";

export const SUPPORT_LOCALES = ["ru", "en", "uz", "tr", "fr", "de", "zh"];

const messages = {
  ru,
  en,
  uz,
  tr,
  fr,
  de,
  zh,
};

export const i18n = createI18n({
  legacy: false,
  locale: "ru",
  fallbackLocale: "ru",
  messages: messages,
});

export function setI18nLang(i18n, locale) {
  i18n.locale.value = locale;

  localStorage.setItem("choyga_lang", locale);

  document.querySelector("html").setAttribute("lang", locale);
}

export function useTranslation(i18n) {
  return function ({ key, value, translations }) {
    if (translations?.[i18n.locale.value]?.[key]) {
      return translations?.[i18n.locale.value]?.[key];
    }

    return value;
  };
}
